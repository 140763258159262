import React from 'react'
import styled from '@emotion/styled'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../../components/LayoutDAI'

import Hero from '../../../components/dai/Hero'
import Section from '../../../components/dai/Section'
import IconCard from '../../../components/dai/IconCard'
import Quote from '../../../components/dai/Quote'
import { Heading1 } from '../../../components/dai/Heading1'
import { Heading2 } from '../../../components/dai/Heading2'
import { Text } from '../../../components/dai/Text'
import Columns from '../../../components/dai/Columns'
import Column from '../../../components/dai/Column'
import PageNavigation from '../../../components/dai/PageNavigation'
import { BoldText } from '../../../components/dai/BoldText'
import { List } from '../../../components/dai/List'
import { Button } from '../../../components/dai/Button'

import SEO from '../../../components/SEO'
import Space from '../../../components/common/Space'
import TextLink from '../../../components/common/TextLink'

import ApplicationWindow from '../../../images/dai/icons/assets/application-window.svg'
import MMS from '../../../images/dai/icons/assets/mms.svg'
import Hide from '../../../images/dai/icons/assets/hide.svg'
import ExternalLink from '../../../images/dai/icons/assets/external-link.svg'

const MaxImage = styled.div`
  max-width: 200px;
`

export default function Travel() {
  return (
    <LayoutDAI
      freeAudit
      showIndustryReports
      pagePath={{
        parent: 'industry-reports',
        child: 'travel',
        freeAuditHeading: 'Find out how your site compares to other travel sites.',
      }}
    >
      <SEO
        title="Travel Accessibility | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/industry-reports/travel/"
        desc="Our expert review of top travel sites found another 15 accessibility barriers across the pages tested, which included account pages and flight/room pickers."
        bannerUrl="https://images.prismic.io/audioeye-web/10c35582-cf6f-487a-8ee6-84313fcc72aa_travel.jpg?auto=compress,format"
        imageAlt="Stylized billboard for the Travel industry with a message that reads “Dates available from Date Picker Unavailable”"
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/industry-reports/travel/`}
      />
      <Hero
        image={
          <StaticImage
            src="../../../images/dai/hero/travel.png"
            alt="Stylized billboard for the Travel industry with a message that reads “Dates available from Date Picker Unavailable”"
          />
        }
        backgroundColor="orange200"
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          TRAVEL
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Travel">
          Accessibility barriers are putting travel plans on hold.
        </Heading1>
        <Space height={32} />
        <Text>
          When we asked members of the disability community what it was like to book travel online, they shared many of
          the same sentiments — and negative experiences.
        </Text>
        <Space height={24} />
        <List>
          <li>
            <Text>
              Forget about shopping around for the best deal on flights or hotels; even using the date picker to enter
              travel dates can be inaccessible.
            </Text>
          </li>
          <li>
            <Text>
              When people with disabilities encounter accessibility barriers on travel sites, they’re quick to visit
              another site.
            </Text>
          </li>
        </List>
        <Space height={24} />
        <Text>
          Given that feedback, it wasn’t surprising that our automated scan and expert audits revealed that travel sites
          have some of the highest accessibility failure rates of any industry.
        </Text>
      </Hero>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“One time I knew my destination, I knew my dates. I was just going to get the ticket ... and I got stuck. It was a bad experience for me as a customer, because I had to switch platforms when I was almost done.”"
          author="Charles Hiser"
          title="AudioEye A11iance Advocate"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          EXPERT AUDITS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Breaking down the most significant barriers on top travel sites.
        </Heading2>
        <Space height={32} />
        <Text>
          After reviewing the top travel sites, our expert testers found a number of issues that made it hard for people
          with disabilities to book travel, such as hotel photos that were missing alternative descriptions for
          non-sighted users and pop-up windows (for selecting rooms and dates) that could not be closed by keyboard-only
          users.
        </Text>
        <Space height={32} />
        <Text>
          On average, our testers found another{' '}
          <BoldText>
            15 accessibility barriers across the pages tested, which included account pages and flight/room pickers
          </BoldText>{' '}
          — including four consistent barriers that impacted their ability to book rooms and flights.
        </Text>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns orderedList>
          <Column size={12} listItem>
            <IconCard noCard>
              <ApplicationWindow />
              <Space height={16} />
              <Text heading3 className="asH4">
                1. Pop-up windows contained no information for non-sighted users.
              </Text>
              <Space height={16} />
              <Text>
                Our experts noted multiple pop-up windows that could trap keyboard-only users, with no audible
                explanation of what the window was for and no way for the user to close it. This forced testers to leave
                the site — and abandon any progress they’d made.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/info-and-relationships.html"
                  target="_blank"
                  rel="noreferrer"
                  text="1.3.1: Info and Relationships"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <MMS />
              <Space height={16} />
              <Text heading3 className="asH4">
                2. Alt text failed to paint a compelling picture for non-sighted users.
              </Text>
              <Space height={16} />
              <Text>
                Our experts found that many images of hotel rooms and amenities were simply labeled “lobby” or “room” —
                instead of providing more descriptive alt text that would help non-sighted users better understand the
                experience of staying at that hotel.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                  target="_blank"
                  rel="noreferrer"
                  text="1.1.1: Non-Text Content"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <Hide />
              <Space height={16} />
              <Text heading3 className="asH4">
                3. Unclear labels made it difficult for non-sighted users to navigate between pages.
              </Text>
              <Space height={16} />
              <Text>
                Our testers encountered numerous page elements that were missing descriptive labels (for example: a
                series of tabs that were each labeled “see more,” instead of more descriptive labels like “reviews” and
                “amenities.”
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual
              </Text>
            </IconCard>
          </Column>
          <Column size={12} listItem>
            <IconCard noCard>
              <ExternalLink />
              <Space height={16} />
              <Text heading3 className="asH4">
                4. Links without labels failed to provide critical context.
              </Text>
              <Space height={16} />
              <Text>
                Our testers also encountered links without descriptive labels, which forced non-sighted users to stop,
                go back, and read the surrounding text to try and figure out where clicking the link would take them.
              </Text>
              <Space height={32} />
              <Text zIndex={2}>
                <BoldText>WCAG Criteria:</BoldText>{' '}
                <TextLink
                  to="https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
                  target="_blank"
                  rel="noreferrer"
                  text="4.1.2: Name, Role, Value"
                  anchorClassName="unstyled-link dai-link"
                />
              </Text>
              <Text>
                <BoldText>Disabilities Affected:</BoldText> Visual
              </Text>
            </IconCard>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Quote
          quoteText="“Travel sites can do a lot of things well. But if the booking process is clunky, that's what [will] stick in everybody's mind.”"
          author="Maxwell Ivey"
          title="AudioEye A11iance Advocate"
        />
      </Section>
      <Section paddingTop="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          AUTOMATED SCAN RESULTS
        </Text>
        <Space height={32} />
        <Heading2 heading2 className="asH3">
          Most common issues, travel sites.
        </Heading2>
        <Space height={32} />
        <Text>
          Our automated scan revealed a number of significant accessibility issues on travel sites, including missing
          image alt text that made it hard to look up seating charts, compare hotels, and more.
        </Text>
      </Section>
      <Section paddingTop="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/square-sign-73.png"
                alt="Pie chart representing 73%. The pie chart is in the shape of a construction road sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              73% of travel pages had at least one image with missing or inadequate alt text.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive alt text on product photos and other graphics, people with visual and cognitive
              disabilities can have a hard time understanding what an image is supposed to illustrate.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/non-text-content.html"
                target="_blank"
                rel="noreferrer"
                text="1.1.1: Non-Text Content"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/square-sign-54.png"
                alt="Pie chart representing 54%. The pie chart is in the shape of a construction road sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              54% of travel pages were missing frame titles.
            </Text>
            <Space height={24} />
            <Text>
              Travel sites had the second highest rate of missing frame titles across all industries scanned, which can
              make it difficult for screen reader users to find content or orient themselves within a page.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/page-titled.html"
                target="_blank"
                rel="noreferrer"
                text="2.4.2: Page Titles"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="md" paddingBottom="lg" constraint="lg" backgroundColor="orange200">
        <Columns>
          <Column size={6} tabletSize={8}>
            <MaxImage>
              <StaticImage
                src="../../../images/dai/stat-sign/square-sign-40.png"
                alt="Pie chart representing 40%. The pie chart is in the shape of a construction road sign."
              />
            </MaxImage>
          </Column>
          <Column size={18} tabletSize={16}>
            <Text heading3 className="asH4">
              40% of travel pages with a form had at least one field that was not labeled.
            </Text>
            <Space height={24} />
            <Text>
              Without descriptive field labels, it can be difficult for people with visual and cognitive impairments to
              sign up for an account or upload their personal information.
            </Text>
            <Space height={24} />
            <Text zIndex={2}>
              <BoldText>WCAG Success Criteria:</BoldText>{' '}
              <TextLink
                to="https://www.w3.org/WAI/WCAG21/Understanding/labels-or-instructions.html"
                target="_blank"
                rel="noreferrer"
                text="3.3.2: Labels or Instructions"
                anchorClassName="unstyled-link dai-link"
              />
            </Text>
            <Text>
              <BoldText>Disabilities Affected:</BoldText> Visual, Cognitive
            </Text>
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="xl" paddingBottom="xl" constraint="lg" backgroundColor="orange300">
        <Columns>
          <Column size={16} tabletSize={14}>
            <Text heading3 className="asH4">
              Get expert insight into the accessibility of booking travel on your site.
            </Text>
            <Space height={32} />
            <Text>
              Uncover accessibility barriers that are making it hard for people with disabilities to search for rooms or
              flights with an expert audit of your site.
            </Text>
          </Column>
          <Column size={8} tabletSize={10} justifyCenter alignItemsEnd>
            <Button
              text="Schedule Expert Audit"
              to="/digital-accessibility-index/industry-reports/travel/#expert-audit"
              size="small"
              tag="DAI Page Content"
            />
          </Column>
        </Columns>
      </Section>
      <PageNavigation
        previousPageLink="/digital-accessibility-index/industry-reports/finance/"
        previousPageText="Financial Services"
        nextPageLink="/digital-accessibility-index/industry-reports/media/"
        nextPageText="Media"
      />
    </LayoutDAI>
  )
}
